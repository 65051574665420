

import { Text, StyleSheet, Pressable } from 'react-native';

export default function Button(props) {
    const { onPress, title = 'Save' } = props;
    return (
        <Pressable style={({ pressed }) => [styles.button,
        pressed && { opacity: .8, backgroundColor: '#9EC4C5', padding: 30 }]}
            onPress={onPress} >
            <Text style={styles.text}>{title}</Text>
        </Pressable >
    );
}

const styles = StyleSheet.create({
    button: {

        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 20,
        paddingHorizontal: 0,
        borderRadius: 10,
        elevation: 16,
        backgroundColor: 'transparent',

    },
    text: {
        fontSize: 70,
        lineHeight: 10,
        fontWeight: '900',
        letterSpacing: 0.25,
        color: '#673AB7',
    },
});