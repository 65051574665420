import { StatusBar } from 'expo-status-bar';
import { useState, useEffect } from 'react';
import { StyleSheet, Text, View, FlatList, Alert } from 'react-native';
import CountDown from './components/CountDown';
import MyButton from './components/MyButton';
import { writeTime, readTimes, getLatestTimes } from './firebase';
import moment from 'moment';
import Ionicons from '@expo/vector-icons/Ionicons';
import SettingsModal from './components/SettingsModal';
import { Pressable } from 'react-native-web';
import alert from './alert'
import { getData } from './asyncStorage'
export default function App() {
  const [feedingTimes, setFeedingTimes] = useState([
    { id: '1', data: { time: { seconds: null } } },
    { id: '2', data: { time: { seconds: null } } },
    { id: '3', data: { time: { seconds: null } } },
  ]);

  const [showModal, setShowModal] = useState(false);

  // const toggleModal = () => {
  //   console.log('toggleModal')
  //   const newShowModal = !showModal;
  //   setShowModal(newShowModal);
  //   console.log(showModal)
  // };

  useEffect(() => {
    async function fetchData() {
      const timesFromDb = await getLatestTimes();
      timesFromDb.length > 0 && setFeedingTimes(timesFromDb);
    }
    fetchData();

  }, []);


  const handleButtonPress = async () => {

    const addFeed = async () => {
      const date = new Date();
      // date.setSeconds(0);
      date.setMilliseconds(0);

      const foundObject = feedingTimes.find((obj) => {
        const objDate = new Date(obj.data.time.seconds * 1000);
        // objDate.setSeconds(0);
        objDate.setMilliseconds(0);
        return objDate.getTime() === date.getTime();
      });

      if (!foundObject) {
        await writeTime(date);
        setFeedingTimes(await getLatestTimes());
      } else {
        // console.log('You have already added a feed at this time');
      }
    }
    
    await getData('@settings').then((settings) => {
      if (!settings.isChecked) {
        addFeed()
      }
      else {
        alert('Send new feed log', 'Are you sure?', { onPress: () => addFeed() })
      }
    }
    )



  }

  return (
    <View style={styles.main}>
      <StatusBar style="auto" />
      {/* <WebNotification
        title="Hello, World!" // the title prop is required
        icon="path/to/image.jpg"
        body="This is a web notification"
        timeout={9000}
        onClickFn={() => window.open('http://www.google.com/', '_blank')} // open your own site on notification click
      /> */}
      <View style={styles.app}>
        <View style={styles.header}>
          <Text style={{ color: '#b499e6', fontSize: 35, fontWeight: '900' }}>Kyrie's Feed App</Text>
        </View>
        <View style={styles.body}>
          <Text style={{ color: '#b499e6', fontSize: 14, fontWeight: '400' }}>Count down to next feed</Text>
          <Text style={{ color: '#b499e6', fontSize: 40, fontWeight: '900' }}><CountDown targettime={feedingTimes[0].data.time.seconds} /></Text>
          <View style={{ width: '100%', marginTop: 100 }}>
            <Text style={{ color: '#b499e6', fontSize: 16, fontWeight: '400', textAlign: 'center' }}>List of previous feed</Text>
            <FlatList
              inverted={false}
              data={feedingTimes}
              renderItem={({ item }) => <Text style={styles.feedTimes}>{moment(item.data.time.seconds * 1000).format('MMMM Do YYYY, h:mm a')}</Text>}
              keyExtractor={item => item.id}
              style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 0, paddingBottom: 6, borderRadius: 10 }}
            />
          </View>
          <View style={styles.button}>
            <MyButton title='log feed' onPress={handleButtonPress} />
          </View>
          <Pressable style={{ flex: 1, flexDirection: 'row' }} onPress={() => setShowModal(true)}>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: 50, width: '70%', justifyContent: 'end', alignItems: 'center' }}>
              <Ionicons name="md-settings" size={20} color="#b499e6" />
              <Text style={{ color: '#b499e6', fontSize: 14, fontWeight: '400', textAlign: 'left', marginLeft: 5 }}>Settings</Text>
              <SettingsModal show={showModal} onClose={() => setShowModal(false)} />
            </View>
          </Pressable>
        </View>
      </View>
    </View >
  );
}

const styles = StyleSheet.create({
  app: {
    height: '100vh',
    width: 400,
    flexDirection: 'column',
    backgroundColor: '#1d242b',
    alignItems: 'center',
    paddingTop: 10,
    marginTop: 30,
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
  },
  header: {
    width: 400,
    flexDirection: 'column',
    backgroundColor: '#1d242b',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 5,
  },
  main: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#1d242b',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    height: '80%',
    width: 400,
    flexDirection: 'column',
    backgroundColor: '#1d242b',
    alignItems: 'center',
    paddingTop: 10,
  },
  feedTimes: {
    backgroundColor: '#353536',
    padding: 20,
    marginVertical: 2,
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 10,
    color: '#b499e6',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#1d242b',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },

});
