import React, { useEffect, useState } from 'react';
import { Alert, Modal, StyleSheet, Text, Pressable, View, CheckBox } from 'react-native';
import { Divider, RadioButton } from 'react-native-paper';
import { storeData, getData } from '../asyncStorage'

const SettingsModal = ({ show, onClose }) => {
    const [modalVisible, setModalVisible] = useState(show);
    const [updateFrequency, setUpdateFrequency] = useState('2');
    const [isChecked, setIsChecked] = useState(true);

    const handleClose = () => {
        setModalVisible(false);
        onClose()
    };

    useEffect(() => {
        setModalVisible(show);
    }, [show]);

    useEffect(() => {
        getData('@settings').then((settings) => {
            if (settings) {
                setUpdateFrequency(settings.value)
                setIsChecked(settings.isChecked)
            }
        })
    }, [])

    useEffect(() => {
        const settings = {
            'value': updateFrequency,
            'isChecked': isChecked
        }
        storeData(settings)
    }, [updateFrequency, isChecked])


    return (
        <View style={styles.centeredView}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    Alert.alert('Modal has been closed.');
                }}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalText}>Settings</Text>
                        <View>
                            <RadioButton.Group
                                onValueChange={(newValue) => setUpdateFrequency(newValue)}
                                value={updateFrequency}
                            >
                                <View>
                                    <RadioButton.Item label="2 hrs" value="2" />
                                </View>
                                <View>
                                    <RadioButton.Item label="2 1/2 hrs" value="2.5" />
                                </View>
                                <View>
                                    <RadioButton.Item label="3 hrs" value="3" />
                                </View>
                                <View>
                                    <RadioButton.Item label="4 hrs" value="4" />
                                </View>
                            </RadioButton.Group>
                            <Divider />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                            <Text style={{ marginRight: 5, alignContent: 'center' }}>Confirm log:</Text>

                            <CheckBox value={isChecked} onValueChange={setIsChecked} />

                        </View>

                        <Pressable
                            style={[styles.button, styles.buttonClose, { marginTop: 20 }]}
                            onPress={handleClose}
                        >
                            <Text style={styles.textStyle}>close</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2d0080',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontWeight: 'bold',
    },
});

export default SettingsModal;