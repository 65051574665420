import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import { getData } from '../asyncStorage';
import moment from 'moment';
import WebNotification from 'react-web-notifications'

const CountDown = ({ targettime }) => {
    const [expired, setExpired] = useState(false);
    const [updateFrequency, setUpdateFrequency] = useState('2');
    const [uiReady, setUiReady] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [notify, setNotify] = useState(false)
    const [loading, setLoading] = useState(false)

    let targetTime = targettime;
    const futureTime = new Date(targetTime * 1000);

    switch (updateFrequency) {
        case '2':
            futureTime.setHours(futureTime.getHours() + 2);
            break;
        case '2.5':
            futureTime.setHours(futureTime.getHours() + 2);
            futureTime.setMinutes(futureTime.getMinutes() + 30);
            break;
        case '3':
            futureTime.setHours(futureTime.getHours() + 3);
            break;
        case '4':
            futureTime.setHours(futureTime.getHours() + 4);
            break;
        default:
            futureTime.setHours(futureTime.getHours() + 2);
            break;
    }

    const now = new Date();
    const tl = futureTime - now;

    useEffect(() => {
        getData('@settings').then((settings) => {
            if (settings) {
                setUpdateFrequency(settings.value);
            }
        });
    }, [targettime]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date();
            const timeleft = futureTime - currentTime;
            setTimeLeft(timeleft);

            if (timeleft < 0) {
                setExpired(true);
            } else {
                setExpired(false);
            }

            if (targettime !== null) {
                setUiReady(true)
                setLoading(false)
            }
            else {
                setLoading(true)
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [futureTime]);

    if (!timeLeft || !uiReady) {
        return null; // Return null until timeLeft is initialized
    }

    const secondsLeft = Math.floor(timeLeft / 1000) % 60;
    const minutesLeft = Math.floor(timeLeft / (1000 * 60)) % 60;
    const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60)) % 24;
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));

    // useEffect(() => {
    //     console.log(minutesLeft)
    //     if (minutesLeft === 0) {
    //         setNotify(true)
    //     }
    // }, [secondsLeft])

    return (

        <View >
            {uiReady && <Text style={!expired ? '' : { color: 'red', fontSize: 16, alignSelf: 'center', fontWeight: 300 }}>{!expired ? `${hoursLeft}h:${minutesLeft}m:${secondsLeft}s` : 'Time passed feed baby asap'}</Text>}
            {uiReady && <Text>{!expired ? '' : moment(futureTime).fromNow()}</Text>}
            {notify && <WebNotification
                title="Hello, World!" // the title prop is required
                icon="path/to/image.jpg"
                body="This is a web notification"
                timeout={9000}
                onClickFn={() => window.open('http://www.google.com/', '_blank')} // open your own site on notification click
            />}
            {!uiReady && <Text style={{ color: 'white' }}>Loading...</Text>}
        </View>
    );
};

export default CountDown;