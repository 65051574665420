import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  limit,
  orderBy,
} from "firebase/firestore";

export async function database() {
  const firebaseConfig = {
    apiKey: process.env.EXPO_PUBLIC_API_KEY,
    authDomain: process.env.EXPO_PUBLIC_API_URL,
    projectId: "feeddb-5351a",
    storageBucket: "feeddb-5351a.appspot.com",
    messagingSenderId: "699479450455",
    appId: "1:699479450455:web:784fabe008e431dac29412",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  return db;
}

export async function writeTime(formattedDate) {
  const db = await database();
  const docRef = await addDoc(collection(db, "times"), { time: formattedDate });
}

export async function readTimes() {
  const db = await database();
  const querySnapshot = await getDocs(collection(db, "times"));
  const updatedTimes = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
  return updatedTimes;
  // setFeedingTimes(updatedTimes);
}

export async function getLatestTimes() {
  const db = await database();
  const timesRef = collection(db, "times");
  const q = query(timesRef, orderBy("time", "desc"), limit(3));
  const querySnapshot = await getDocs(q);
  const updatedTimes = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
  return updatedTimes;
}

export async function deleteTime(id) {
  const db = await database();
  const collection = database().collection("times");
  try {
    await collection.doc(id).delete();
  } catch (error) {
    console.error("Error deleting time:", error);
  }
}
