import { Alert, Platform } from 'react-native'

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.style !== 'cancel'
        result && options.onPress()
        // console.log(result)
    } else {
        // console.log(result)
        const cancelOption = options.style === 'cancel'

    }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

export default alert