
import AsyncStorage from '@react-native-async-storage/async-storage';


export const storeData = async (value) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem('@settings', jsonValue)
    } catch (e) {
        console.log(e)
    }
}

export const getData = async (item) => {
    try {
        const jsonValue = await AsyncStorage.getItem(item)
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        console.log(e)
    }
}
